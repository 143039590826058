var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title", { attrs: { "show-back-btn": "" } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.detail,
              expression: "loading.detail"
            }
          ],
          staticClass: "partition-area"
        },
        [
          _c(
            "div",
            { staticClass: "container-fluid theme-green fancyboxfluid" },
            [
              _c("div", { attrs: { id: "main" } }, [
                _c(
                  "div",
                  {
                    staticStyle: { "min-height": "150px", margin: "0" },
                    attrs: { id: "container" }
                  },
                  [
                    _c("div", { staticClass: "col_main" }, [
                      _c("div", { staticClass: "article_show" }, [
                        _c("h2", { staticClass: "title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.record.informationTitle) + " "
                          )
                        ]),
                        _c("div", { staticClass: "info" }, [
                          _c("div", { staticClass: "cat" }, [
                            _vm._v(
                              " 消息类型：" +
                                _vm._s(
                                  _vm.utils.formatMessageType(
                                    _vm.record.informationType
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "author" }),
                          _c("div", { staticClass: "posttime" }, [
                            _vm._v(
                              " 发布时间：" +
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.record.createdAt
                                  )
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "scontent ckedit_content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "cke_editable cke_editable_themed cke_contents_ltr cke_show_borders"
                            },
                            [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.record.informationContent
                                  )
                                }
                              })
                            ]
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }