<!-- 消息通知详情 -->
<!--页面元素-->
<template>
  <el-main>
    <page-title show-back-btn />
    <div v-loading="loading.detail" class="partition-area">
      <div class="container-fluid theme-green fancyboxfluid">
        <div id="main">
          <div id="container" style="min-height:150px;margin:0;">
            <div class="col_main">
              <div class="article_show">
                <h2 class="title">
                  {{ record.informationTitle }}
                </h2>
                <div class="info">
                  <div class="cat">
                    消息类型：{{ utils.formatMessageType(record.informationType) }}
                  </div>
                  <div class="author" />
                  <div class="posttime">
                    发布时间：{{ utils.isEffectiveCommon(record.createdAt) }}
                  </div>
                </div>
                <div class="scontent ckedit_content">
                  <div class="cke_editable cke_editable_themed cke_contents_ltr cke_show_borders">
                    <p v-html="record.informationContent" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-main>
</template>
<script>
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  components: {},
  mixins: [routeEnterMixin],
  data () {
    return {
      id: '', // 消息id
      activeName: 'check',
      listPage: [],
      record: {}, // 消息详情数据
      loading: {
        detail: false,
      },
    }
  },
  watch: {},
  created () {
    this.id = this.$route.query.id
    if (this.id) {
      this.getDataByCode()
      this.getReadById()
    }
  },
  methods: {
    // 修改状态
    getReadById () {
      this.api.workbench.readById(this.id).then(result => {
        this.getDataByCode()
      }).catch(e => {
      })
    },
    countMsg () {
      this.api.workbench.countMsg().then(result => {
        this.$store.commit('updateQuantity', result.data.data)
      })
    },
    // 通过code 获得授信数据
    getDataByCode () {
      this.loading.detail = true
      this.api.workbench.workbenchDetail(this.id).then(result => {
        this.countMsg()
        this.record = result.data.data
      }).finally(() => {
        this.loading.detail = false
      })
    }
  }
}
</script>
<style>
.article_show .title {
  text-align: center;
  font-size: 24px;
  padding-top: 20px;
}
.article_show .info {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
  color: #999;
}
.article_show .info .cat {
  display: inline-block;
}
.article_show .info .author {
  display: inline-block;
  margin: 0 40px;
}
.article_show .info .posttime {
  display: inline-block;
}
.article_show .scontent {
  margin: 40px 40px 0;
  padding: 0 0 40px;
}
.ckedit_content .cke_editable {
  font-size: 14px;
  line-height: 1.6em;
}
</style>
